<template>
  <div>
    <ListView
      title="Products"
      @reload="reload"
      @create="actionCreateProductStock"
      :page.sync="page"
      :search.sync="search"
      :data="data"
      :header="header"
      :loading="isLoading"
    >
      <template v-slot:item.name="{ item }">
        {{ item.product.name ? parseLocalizedString(item.product.name).en : '' }}
      </template>
      <template v-slot:item.stock="{ item }">
        <div v-if="item.activityLog">
          <span class="font-weight-bold">
            {{ item.activityLog.beforeStock }}
          </span>

          <v-icon
            :color="
              item.activityLog.afterStock === item.activityLog.beforeStock
                ? 'gray'
                : item.activityLog.afterStock > item.activityLog.beforeStock
                ? 'success'
                : 'error'
            "
            >mdi-arrow-right</v-icon
          >
          <span class="font-weight-bold"> {{ item.activityLog.afterStock }}</span>
          <span
            v-if="item.activityLog.afterStock > item.activityLog.beforeStock"
            class="ml-3"
            style="color: #8ddc88"
            >(+{{ item.activityLog.afterStock - item.activityLog.beforeStock }})</span
          >
          <span
            v-if="item.activityLog.afterStock < item.activityLog.beforeStock"
            class="ml-3"
            style="color: #ff5252"
            >(-{{ item.activityLog.beforeStock - item.activityLog.afterStock }})</span
          >
        </div>
        <div v-else class="font-weight-bold">{{ item._stock }}</div>
      </template>
      <template v-slot:item.reason="{ item }">
        <div>
          {{ get(item, 'activityLog.updateReason', '-') }}
          <template v-if="get(item, 'activityLog.remarks', false)">
            ({{ item.activityLog.remarks }})
          </template>
        </div>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-icon @click="actionDetailsProductStock(item)">mdi-pencil</v-icon>
      </template>
      <template v-slot:item.detail="{ item }">
        <v-icon @click="actionDetailsActivityLog(item)">mdi-menu</v-icon>
      </template>
    </ListView>
    <ProductListDialog
      v-if="displayProductList"
      v-model="displayProductList"
      :id="id"
      @saveProducts="assignWarehouseStock"
    />
    <WarehouseStockDetailDialog
      v-if="displayWarehouseStock"
      v-model="displayWarehouseStock"
      :warehouseStockId="warehouseStockId"
      @updateWarehouseStock="saveWarehouseStock"
    />
    <ProductActivityLogDialog
      :key="productId"
      :show="displayProductActivityLogDialog"
      :warehouse="warehouse"
      :productId="productId"
      :productName="productName"
      @close="displayProductActivityLogDialog = false"
    />
  </div>
</template>

<script>
import api from '@/api';
import { mapGetters } from 'vuex';

import ProductListDialog from '@/components/PhysicalProduct/ProductListDialog';
import ProductStoreDetailDialog from '@/components/ProductStore/ProductStoreDetailDialog';
import QrcodeVue from 'qrcode.vue';
import { crudCreate, crudRead, crudUpdate } from '@/api/crud';
import { assignStoreProducts, updateStoreProduct } from '@/api/productStore';
import { RESTFUL } from '@/data/constants';
import ListView from '@/components/ListView';
import listViewPageMixin from '@/services/listViewPageMixin';
import { getOid } from '@/services/utils';
import { parseLocalizedString } from '@/services/utils';
import WarehouseStockDetailDialog from '@/components/WarehouseStock/WarehouseStockDetailDialog.vue';
import { get } from 'lodash';
import ProductActivityLogDialog from '@/components/ProductActivityLog/ProductActivityLogDialog';
import { assignWarehouseStock, updateWarehouseStock } from '@/api/warehouseStock';

export default {
  name: 'WarehouseProductList',
  components: { ListView, ProductListDialog, WarehouseStockDetailDialog, ProductActivityLogDialog },
  mixins: [listViewPageMixin],
  props: {
    id: '',
  },
  data() {
    return {
      details: null,
      header: [
        { text: 'Product Name', value: 'name' },
        // { text: 'Quantity', value: 'qty' },
        { text: 'Stock', value: 'stock', width: '200px' },
        { text: 'Edit', value: 'edit', width: '40px', align: 'left' },
        { text: 'Reason', value: 'reason' },
        { text: 'Detail', value: 'detail' },
      ],
      data: [],
      search: '',
      displayProductList: false,
      displayProductDetailDialog: false,
      productStoreId: '',
      displayWarehouseStock: false,
      displayProductActivityLogDialog: false,
      productId: '',
      warehouse: '',
      productName: '',
    };
  },
  mounted() {
    this.loadStore();
  },
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin', 'isMaster']),
    isNew() {
      return this.id === 'new';
    },
    restfulURL() {
      return `toca/warehouse-stocks/${this.id}/products`;
    },
  },
  methods: {
    parseLocalizedString,
    get,
    async loadStore() {
      if (this.isNew) {
        this.details = {};
      } else {
        this.details = await crudRead(api, RESTFUL.warehouses.read, this.id);
        // this.warehouseDocs = await getWarehousesByStoreId(api, this.id);
        // console.log(this.warehouseDocs, 'wrong data');
      }

      // this.warehouseDocs?.sort((a, b) => a?.tableNo - b?.tableNo);
    },
    async actionCreateProductStock() {
      this.displayProductList = true;
    },
    actionDetailsProductStock(warehouseStock) {
      this.displayWarehouseStock = true;
      this.warehouseStockId = warehouseStock._id.toString();
    },
    async assignWarehouseStock(productsData) {
      const productIds = productsData.products.map(p => getOid(p));
      const removeProductIds = productsData.removeProductIds;

      try {
        // if (!(await this.$refs.form?.validate?.())) {
        //   return;
        // }
        if (this.isNew) {
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Create Store First',
            type: 'error',
            color: 'error',
          });
          return;
        }

        const model = await assignWarehouseStock(api, this.id, {
          productIds,
          removeProductIds,
        });

        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });

        // if (this.isNew) {
        //   this.$router.replace({
        //     name: 'vipProduct',
        //     params: {
        //       id: model._id,
        //     },
        //   });
        // }
        this.displayProductList = false;
        this.reload();
      } catch (e) {
        console.log(e);
      }
    },
    async saveWarehouseStock(warehouseStockData) {
      try {
        if (this.isNew) {
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Create Store First',
            type: 'error',
            color: 'error',
          });
          return;
        }

        const model = await updateWarehouseStock(api, this.warehouseStockId, warehouseStockData);

        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });

        this.displayWarehouseStock = false;
        this.reload();
      } catch (e) {
        console.log(e);
      }
    },
    actionDetailsActivityLog(warehouseStock) {
      this.warehouse = warehouseStock.warehouse;
      this.productId = warehouseStock.product._id.toString();
      this.productName = warehouseStock.product.name
        ? parseLocalizedString(warehouseStock.product.name).en
        : '';

      this.displayProductActivityLogDialog = true;
    },
  },
};
</script>
