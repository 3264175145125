var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('div',[_c('h2',[_vm._v("Discount Details")])]),_c('v-divider',{staticClass:"mb-5"}),(_vm.details)?_c('div',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{staticClass:"mr-3 ml-3"},[_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[_c('v-btn',{attrs:{"disabled":invalid || _vm.isMaster,"color":"primary"},on:{"click":_vm.debounceSave}},[_vm._v(" Save ")])],1)],1),_c('v-form',[_c('v-row',{staticClass:"mr-3 ml-3"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex itemFieldName mb-2",staticStyle:{"gap":"10px"}},[_vm._v(" Is Active: "),_c('v-switch',{staticClass:"mt-0 pt-0 ml-3",attrs:{"hide-details":""},model:{value:(_vm.details.isActiveForPOS),callback:function ($$v) {_vm.$set(_vm.details, "isActiveForPOS", $$v)},expression:"details.isActiveForPOS"}})],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('custom-label',{attrs:{"item-field-name":"Name"}}),_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Name","error":!!errors.length,"error-messages":errors},model:{value:(_vm.details.name),callback:function ($$v) {_vm.$set(_vm.details, "name", $$v)},expression:"details.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('custom-label',{attrs:{"item-field-name":"Type"}}),_c('ValidationProvider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":[
                        { text: 'Fixed', value: 'fixed' },
                        { text: 'Percent', value: 'percent' } ],"outlined":"","error":!!errors.length,"error-messages":errors},model:{value:(_vm.details.type),callback:function ($$v) {_vm.$set(_vm.details, "type", $$v)},expression:"details.type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('custom-label',{attrs:{"item-field-name":"Value"}}),_c('ValidationProvider',{attrs:{"name":"Value","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Value","error":!!errors.length,"error-messages":errors},model:{value:(_vm.details.value),callback:function ($$v) {_vm.$set(_vm.details, "value", $$v)},expression:"details.value"}})]}}],null,true)})],1)],1)],1)],1)],1)]}}],null,false,3742155661)})],1):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"padding-top":"20%"}},[_c('h2',[_vm._v("No Record to found")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }