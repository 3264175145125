<template>
  <v-container :fluid="isFluid">
    <v-row>
      <v-col>
        <div class="d-flex">
          <h2 class="mainTitle">{{ title }}</h2>
          <v-spacer />
          <slot name="btn"></slot>
          <slot name="action">
            <v-btn text @click="debounceReload">
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-btn icon color="primary" v-if="enableCreate" @click="$emit('create')">
              <v-icon>mdi-plus-thick</v-icon>
            </v-btn>
          </slot>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="flex: 1">
        <slot name="searchBox" :ctx="this">
          <v-text-field
            solo
            filled
            prepend-inner-icon="mdi-magnify"
            v-model="searchVal"
            hide-details="auto"
            :placeholder="searchPlaceholer"
          />
        </slot>
      </v-col>
      <v-col v-if="enableSelectItemsPerPage" cols="auto">
        <v-select
          v-model="itemsPerPage"
          label="Items per page"
          :items="itemsPerPageOptions"
          messages="Items per page"
          solo
          filled
          :suffix="itemsPerPage ? 'items' : ''"
          style="max-width: 130px"
        />
      </v-col>
      <v-col cols="auto" v-if="$scopedSlots.filterRight">
        <slot name="filterRight"></slot>
      </v-col>
      <v-col cols="12" v-if="$scopedSlots.filter">
        <slot name="filter"></slot>
      </v-col>
    </v-row>

    <!--    <v-row v-if="$vuetify.breakpoint.mobile">-->
    <!--      <v-col :cols="cols" :key="x._id" v-for="x in data.docs">-->
    <!--        <slot name="card" :cardContent="x"></slot>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <v-data-table
      :items="data.docs"
      :server-items-length="data.pages || 1"
      :items-per-page="limit || 10"
      :disable-sort="true"
      hide-default-footer
      :headers="header"
      :calculate-widths="isFluid"
      :loading="loading"
      @click:row="x => $emit('navDetails', x)"
    >
      <template v-slot:item.time="{ item }">
        {{ item.time | datetimeRange }}
      </template>
      <template v-slot:item.status="{ item }">
        <div :style="{ color: getColor(item.status) }" label>
          {{ item.status | status }}
        </div>
      </template>
      <template v-for="(_, name) in $scopedSlots" v-slot:[name]="props">
        <slot :name="name" v-bind="props" />
      </template>
    </v-data-table>
    <v-pagination
      v-if="!hidePagination"
      class="mt-4"
      v-model="pageVal"
      :length="data.pages || 1"
      :total-visible="10"
    />
    <slot></slot>
  </v-container>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'ListView',
  props: {
    title: {},
    search: {},
    page: {},
    limit: { default: 10 },
    data: { default: () => ({}) },
    enableCreate: { default: true },
    populate: {},
    cols: { type: Number, default: 12 },
    header: {
      default: () => [{ text: 'ID', value: '_id' }],
    },
    isFluid: { default: false },
    hidePagination: { type: Boolean, default: false },
    enableSelectItemsPerPage: { type: Boolean, default: false },
    loading: { default: false },
    searchPlaceholer: { default: 'Search' },
  },
  emits: ['create', 'reload', 'navDetails'],
  data() {
    return {
      itemsPerPageOptions: [10, 25, 50, 100],
      debounceReload: debounce(() => this.$emit('reload'), 500),
    };
  },
  computed: {
    searchVal: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit('update:search', val);
      },
    },
    pageVal: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit('update:page', val);
      },
    },
    itemsPerPage: {
      get() {
        return this.limit;
      },
      set(val) {
        this.$emit('update:limit', val);
      },
    },
  },
  methods: {
    debounce,
    getColor(status) {
      if (status === '97$cancelled' || status === '98$absent') return 'red';
      // else return 'green'
    },
  },
};
</script>
