<template>
  <v-dialog v-model="valueProxy" width="60%" max-width="100vw">
    <ValidationObserver ref="form" v-slot="{ valid }">
      <v-card elevation="0" min-height="250">
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Stock" />
                <ValidationProvider v-slot="{ errors }" name="_stock" rules="required">
                  <v-text-field
                    v-model="model._stock"
                    :error="!!errors.length"
                    :error-messages="errors"
                    type="number"
                    outlined
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <custom-label item-field-name="Reason" />
                <ValidationProvider v-slot="{ errors }" name="Reason" rules="required">
                  <v-select
                    v-model="model.updateReason"
                    outlined
                    :items="productActivityLogUpdateReasonOptions"
                    :error="!!errors.length"
                    :error-messages="errors"
                  ></v-select>
                </ValidationProvider>
                <template v-if="model.updateReason === ProductActivityLogUpdateReason.Other">
                  <ValidationProvider v-slot="{ errors }" name="Other Reason" rules="required">
                    <v-text-field
                      v-model="model.remarks"
                      label="Other Reason"
                      outlined
                      :error="!!errors.length"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
              </v-col>
              <!-- <v-col cols="12" md="6">
                <custom-label item-field-name="Quanity" />
                <ValidationProvider v-slot="{ errors }" name="Quantity" rules="required">
                  <v-text-field
                    v-model="model.qty"
                    :error="!!errors.length"
                    :error-messages="errors"
                    type="number"
                  ></v-text-field>
                </ValidationProvider>
              </v-col> -->
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <!---<v-btn color="primary" :disabled="!valid" @click="promptCreate()" v-if="isCreate">-->
          <v-btn color="primary" @click="debouncePromptUpdate"> Update</v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import api from '@/api';
import editDialogMixin from '@/services/editDialogMixin';
import CustomLabel from '../customFormLabel/customLabel';
import { cloneDeep, debounce, get } from 'lodash';
import {
  creditRecordAction,
  creditRecordStatus,
  productActivityLogUpdateReasonOptions,
} from '@/data/optionsConstants';
import Autocomplete from '@/components/Autocomplete/Autocomplete';
import { getNameFromProfile } from '@/services/filters';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { ProductActivityLogUpdateReason, RESTFUL } from '@/data/constants';
import { crudRead } from '@/api/crud';

export default {
  name: 'WarehouseStockDetailDialog',
  components: {
    CustomLabel,
    // Autocomplete,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [editDialogMixin],
  emits: ['create'],
  props: {
    title: {},
    warehouseStockId: '',
  },
  data() {
    return {
      productActivityLogUpdateReasonOptions,
      ProductActivityLogUpdateReason,
      creditRecordAction,
      creditRecordStatus,
      model: {
        action: 'topup',
        status: '1$active',
        created_by: this.clientId ? [this.clientId] : [],
      },
      debouncePromptUpdate: debounce(this.promptUpdate, 500),
    };
  },
  watch: {
    warehouseStockId: {
      immediate: true,
      handler(v) {
        if (v) {
          this.reload();
        }
      },
    },
  },
  computed: {
    ...mapGetters('auth', ['profile']),
  },
  methods: {
    getNameFromProfile,
    get,
    moment,
    async reload() {
      this.model = await crudRead(api, RESTFUL.warehouseStocks.read, this.warehouseStockId);
    },
    async promptUpdate() {
      if (!(await this.$refs.form?.validate?.())) {
        return;
      }

      this.$emit('updateWarehouseStock', this.model);
    },
  },
};
</script>

<style scoped lang="scss">
.overrideVCardText {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
