<template>
  <v-dialog v-model="valueProxy">
    <v-card>
      <v-card-title primary-title class="justify-center">
        <v-spacer />
        <v-btn icon @click="doClose()">
          <v-icon dense> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-tabs v-model="activeTab">
        <v-tab v-for="(tab, index) in tabs" :key="index">
          {{ tab.title }}
        </v-tab>
        <!-- <v-tab-item v-for="(tab, index) in tabs" :key="index">
            {{ tab.value }}
          </v-tab-item> -->
      </v-tabs>
      <ListView
        :title="''"
        @reload="reload"
        :data="data"
        :header="header"
        :page.sync="page"
        :search.sync="search"
        :loading="isLoading"
        enableSelect
        enableActionBtn
        v-model="selectedData"
        :enableCreate="false"
      >
        <!-- <template v-slot:filter v-if="showIssueReport">
                <v-tabs v-model="tab">
                  <v-tab @click="switchTab(`/lkt/v1/admin/LKTAssetLog/container/${container?._id}`)">{{
                    $t('tab.asset_logs')
                  }}</v-tab>
                  // TODO: handle container issue report if need to display
                  <v-tab @click="switchTab('lkt/v1/staff/LKTAssetIssueReports')">{{
                    $t('tab.issue_reports')
                  }}</v-tab>
                </v-tabs>
              </template>
              <template v-slot:item.title="{ item }">
                {{ logsTitleTranslation(item?.title) }}
              </template>
              <template v-slot:item.activity="{ item }">
                {{ ActivityTypeMap[item?.title] }}
              </template>
              <template v-slot:item.recordDate="{ item }">
                {{ item?.recordDate ?? item?.created_at | datePrettify }}
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip :color="StatusColorMap[item?.status]" class="statusChip">
                  <span style="color: var(--v-white_light-base)">{{ item?.status | status }}</span>
                </v-chip>
              </template>
              <template v-slot:item.followUp="{ item }">
                <v-custom-btn @click.stop="showFollowUpForm(item)">Follow Up</v-custom-btn>
              </template> -->
      </ListView>
      <v-card-title
        primary-title
        :disabled="isLoading"
        @click="debounceSave"
        class="justify-center"
      >
        <v-btn>save</v-btn>
      </v-card-title>
    </v-card>

    <!-- <custom-dialog ref="confirm"></custom-dialog> -->
  </v-dialog>
</template>

<script>
import listViewPageMixin from '@/services/listViewPageMixin';
import ListView from '@/components/ListView/custom';
import { debounce } from 'lodash';

// import CustomDialog from '@/components/CustomDialog';
import api from '@/api';
import { mapGetters } from 'vuex';
import { getMatchedUsers } from '@/api/storeUser';

export default {
  name: 'UserListDialog',
  mixins: [listViewPageMixin],
  components: {
    ListView,
    // CustomDialog,
  },
  props: {
    id: '',
    container: { type: Object, default: () => {} },
    value: { type: Boolean, default: false },
  },
  data() {
    return {
      header: [{ text: 'Name', value: 'profiles.[0].name' }],
      selectedData: [],
      activeTab: 0,
      removedIds: [],
      populate: [{ path: 'profiles' }],
      tabs: [{ title: 'Cashier', value: 'toca.cashier' }],
      debounceSave: debounce(this.saveAndClose, 500),
    };
  },
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin']),

    checkRole() {
      return this.role !== roles.master;
    },
    restfulURL() {
      return `users`;
    },
    valueProxy: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        if (!value) {
          this.$emit('close');
        }
      },
    },
    filter() {
      return { _roles: this.tabs[this.activeTab].value };
    },
  },
  watch: {
    // restfulURL: {
    //   immediate: true,
    //   handler() {
    //     this.reload();
    //   },
    // },
    data: {
      // immediate: true,
      handler(data) {
        // NOTE: WHEN NEW DATA

        const prepData = data.docs?.map(doc => doc);

        this.getMatchedUsers(prepData?.map(d => d._id.toString()));
      },
    },
    selectedData: {
      handler(selectedData) {
        const removedArray = this.data.docs
          .map(d => d._id.toString())
          .filter(element => !selectedData.map(d => d._id.toString()).includes(element));
        console.log(removedArray, 'wat wat lolsllls');
        const prepIds = [...new Set([...this.removedIds, ...removedArray])];
        this.removedIds = prepIds;
      },
    },
  },
  methods: {
    // logsTitleTranslation(title) {
    //   const translated = i18n.t(`containerLogTitle.${title}`);
    //   return !translated.includes('containerLogTitle.') ? translated : title;
    // },

    showCreate() {
      this.editItem = null;
      if (this.isAssetLog) this.showAssetLogEditDialog = true;
      else this.showIssueReportEditDialog = true;
    },
    navDetails(item) {
      this.editItem = item;
      if (this.isAssetLog) this.showAssetLogEditDialog = true;
      else this.showIssueReportEditDialog = true;
    },
    // showFollowUpForm(item) {
    //   this.showFollowUpEditDialog = true;
    //   this.editItem = item;
    // },
    // switchTab(url) {
    //   this.selectedURL = url;
    //   this.page = 1;
    // },
    doClose() {
      this.valueProxy = false;
    },
    // async createLog(newModel) {
    //   const isConfirm = await this.$refs.confirm.open(
    //     'Confirm Create',
    //     `Create New ${newModel?.title ?? ''}?`,
    //   );
    //   if (!isConfirm) return;
    //   try {
    //     await createAssetLog(api, newModel);
    //     this.successPrompt('Created Successfully');
    //     this.reload();
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    // async updateLog(newModel) {
    //   const isConfirm = await this.$refs.confirm.open(
    //     'Confirm Update',
    //     `Update ${newModel?.title}?`,
    //   );
    //   if (!isConfirm) return;
    //   try {
    //     await updateAssetLog(api, newModel?._id, newModel);
    //     this.successPrompt('Updated Successfully');
    //     this.reload();
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    async successPrompt(msg) {
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: msg ? msg : 'Success',
        type: 'success',
        color: 'success',
      });
    },
    saveAndClose() {
      this.$emit('saveUsers', {
        users: this.selectedData,
        removeUserIds: this.removedIds,
      });
    },
    async getMatchedUsers(userListIds) {
      // TODO: CONTROL FROM OUTER COMPONENT
      const fetchedData = await getMatchedUsers(api, this.id, {
        userIds: userListIds,
      });

      this.selectedData = [...new Set([...this.selectedData, ...fetchedData])];
    },
  },
};
</script>

<style lang="scss" scoped></style>
